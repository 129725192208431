import React from "react";

function SelectedSpotsImportButton({isSuccess, inQueue, isImporting, isLoading, spotsCount, importSpots, client, importingProgress, importingDuration, importingMessage})
{
    return (
        isLoading ? (
            <button className="button button--large button--disabled button--icon-spin">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"/>
                </svg>
                <span>Fetching {spotsCount} spots</span>
            </button>
        ) : !isSuccess && !isImporting ? (
            <button className="button button--large button--primary" disabled={spotsCount < 1} onClick={importSpots}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                        d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/>
                </svg>
                {spotsCount === 1 ? (
                    <span>Add spot to shots Reels</span>
                ) : (
                    <span>Add {spotsCount} spots to shots Reels</span>
                )}
            </button>
        ) : !isSuccess && isImporting ? (
            <div className="from-source-modal__progress">
                <div className="from-source-modal__progress__bar">
                    <div className="from-source-modal__progress__bar__progress" style={{width: importingProgress + '%', transitionDuration: importingDuration + 's' }}></div>
                </div>
                <div className="from-source-modal__progress__text">
                    {importingMessage}
                </div>
            </div>
        ) : isSuccess && inQueue ? (
            <>
            <a href={'https://' + client + '.slateapp.com/admin/media/list'} target="_blank" rel="noreferrer"
               className="button button--large button--primary">
                <span>Go to my Library</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                        d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/>
                </svg>
            </a>
            <div className="from-source-modal__actions__message">Queued spots usually appear within a few minutes</div>
            </>
        ) : (
            <a href={'https://' + client + '.slateapp.com/admin/media/list'} target="_blank" rel="noreferrer"
               className="button button--large button--primary">
                <span>View in your library</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                        d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/>
                </svg>
            </a>
        )
    );
}

export default SelectedSpotsImportButton;