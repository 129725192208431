import React, {useEffect, useState, useRef} from "react";
import './styles/style.scss';
import {fetchSourceSpots} from "./api/SourceApi";
import {toast} from "react-toastify";
import {buildClientInstanceUri, fetchImportedSpots, postSpotsToImporterQueue} from "./api/SlateApi";
import {useStoreActions, useStoreState} from "easy-peasy";
import cn from 'classnames';
import SelectedSpotsHeader from "./components/SelectedSpotsHeader";
import {PuffLoader} from "react-spinners";
import SelectedSpotsImportButton from "./components/SelectedSpotsImportButton";

function SelectedSpotsApp()
{
    const urlSearch = new URLSearchParams(window.location.search);
    const spotsIds = urlSearch.get('spots') || '';
    const csUser = urlSearch.get('cs') || '';

    const getClientId = () => {
        if (csUser) {
            return localStorage.getItem('client_id') || urlSearch.get('client');
        }

        return urlSearch.get('client');
    };
    const [client, setClientId] = useState(getClientId());

    const setClient = client => {
        localStorage.setItem('client_id', client);
        setClientId(client);
    };

    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isImporting, setImporting] = useState(false);
    const [importingProgress, setImportingProgress] = useState(0);
    const [importingDuration, setImportingDuration] = useState(3);
    const [importingMessage, setImportingMessage] = useState('Importing spots...');
    const [isSuccess, setSuccess] = useState(false);
    const [inQueue, setInQueue] = useState(false);
    const [detailsLoading, setDetailsLoading] = useState(false);

    const spotsData = useStoreState((state) => state.spots.data)
    const setSpots = useStoreActions((action) => action.setSpotsFromSource)
    const removeSpotAction = useStoreActions((action) => action.removeSpot)
    const fetchSpotsDetails = useStoreActions((action) => action.fetchSpotsTrustedDetails)
    const setImportedSpots = useStoreActions((action) => action.setImportedSpots)
    const markSpotsAsImported = useStoreActions((action) => action.markSpotsAsImported)

    const [isFirstRun, setFirstRun] = useState(() => {
        const savedFirstRun = localStorage.getItem('slateImporterFirstRun');
        return savedFirstRun || 'true';
    });
    const clearFirstRun = () => {
        setFirstRun('false');
        localStorage.setItem('slateImporterFirstRun', 'false');
    }

    const messageTimeout1 = useRef(null);
    const messageTimeout2 = useRef(null);

    const importSpots = async e => {
        e.preventDefault();

        if (spotsData.length < 1) {
            return toast.error('You must select at least one spot.', {autoClose: 3000});
        }

        setImporting(true);

        // Creating dummy loading animation based on number of spots
        const progressDuration = (spotsData.length * 1.5) > 3 ? (spotsData.length * 1.5) : 3;
        setTimeout(function() {
            setImportingProgress(90);
            setImportingDuration(progressDuration);
        },50);
        messageTimeout1.current = setTimeout(() => {
            setImportingMessage('Processing media...');
        }, (progressDuration/3)*1000);
        messageTimeout2.current = setTimeout(() => {
            setImportingMessage('Updating library...');
        }, (progressDuration/3)*2000);

        try {
            const response = await postSpotsToImporterQueue(client, spotsData, false);
            if (response.status === 200) {

                // Complete progress animation
                clearTimeout(messageTimeout1.current);
                clearTimeout(messageTimeout2.current);
                setImportingProgress(100);
                setImportingDuration(1);
                setImportingMessage('Updating library...');

                // When animation is complete...
                setTimeout(function() {
                    setImporting(false);
                    setSuccess(true);

                    if (response?.data?.response) {
                        setInQueue(false);
                        const spots = JSON.parse(response.data.response).spots;
                        markSpotsAsImported(spots || []);
                    } else {
                        setInQueue(true);
                    }
                }, 1000);
            }
        } catch (e) {
            setImporting(false);
            toast.error('Unable to send request to import queue. Please try again in a moment.');
        }
    };

    const removeSpot = (e) => {
        e.preventDefault();

        const id = e.currentTarget.getAttribute('data-spot-id');
        removeSpotAction(id);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        try {
            const response = await fetchSourceSpots(spotsIds.split(','));
            setSpots(response.data.Items);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError(true);
            if (e.response?.status === 401) {
                toast.error('Authorization has been denied. Try to log in again or ask support.', {
                    toastId: "unauthorized",
                    autoClose: false,
                });
            } else {
                toast.error('Unable to fetch spots from Source Creative. Trying again within 10 sec.', {
                    toastId: "source_fetch_issue",
                });
            }

            return;
        }

        setDetailsLoading(true);
        fetchSpotsDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const spotsIds = spotsData.map(spot => spot.id);

        if (spotsIds.length < 1) {
            return;
        }

        try {
            const importedSpotsResponse = await fetchImportedSpots(client, spotsIds);
            setImportedSpots(importedSpotsResponse.data.spots || []);
        } catch (e) {
            if (e === 'client_name_required') {
                toast.warn('Unable to check status of spots in your library. ClientId not valid.', {
                    toastId: "slate_fetch_imported_failed",
                });
            } else {
                toast.error('Unable to check status of spots in your library.', {
                    toastId: "slate_fetch_imported_failed",
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, isLoading])

    useEffect(() => {
        let done = 0;

        spotsData.forEach(spot => {
            if (spot.loaded || spot.fetchingError === true) {
                done++;
            }
        });

        if (spotsData.length > 0 && done === spotsData.length) {
            setDetailsLoading(false)
        }
    }, [spotsData]);

    return (

        <div className="from-source-modal">

        {isFirstRun !== 'false' ? (

            <div className="from-source-modal__first-run">
                <div className="from-source-modal__first-run__title">
                    <span>Add spots to</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 264 128">
                        <path fill="#282E2F" d="M250.684 98.6c-7.14 0-13.26-3.3-13.44-10.68l7.98-.24c.24 2.7 2.46 4.44 5.64 4.44 2.46 0 4.26-.96 4.26-2.7 0-5.16-16.74-3.18-16.74-14.04 0-5.82 5.22-9.36 11.76-9.36 7.32 0 12.24 4.26 12.36 9.9l-7.86.24c-.24-2.4-1.86-3.9-4.56-3.9-2.04 0-3.78.84-3.78 2.52 0 5.16 17.04 2.94 17.04 14.16 0 6.12-5.28 9.66-12.66 9.66ZM226.064 53h8.16v45h-8.16V53ZM207.89 66.02c8.94 0 15.24 6.12 14.82 18.84h-21.42c.36 4.14 3.3 6.96 7.14 6.96 3.24 0 5.46-1.74 6.6-4.38l7.14.78c-1.5 6.12-7.02 10.38-13.8 10.38-9.06 0-15.3-6.3-15.3-16.2 0-9.66 6-16.38 14.82-16.38Zm0 6.6c-3.72 0-6.42 2.58-6.6 6.66h13.2c-.06-3.96-2.76-6.66-6.6-6.66ZM176.336 66.02c8.94 0 15.24 6.12 14.82 18.84h-21.42c.36 4.14 3.3 6.96 7.14 6.96 3.24 0 5.46-1.74 6.6-4.38l7.14.78c-1.5 6.12-7.02 10.38-13.8 10.38-9.06 0-15.3-6.3-15.3-16.2 0-9.66 6-16.38 14.82-16.38Zm0 6.6c-3.72 0-6.42 2.58-6.6 6.66h13.2c-.06-3.96-2.76-6.66-6.6-6.66ZM150.78 66.68l1.2 4.32c1.44-3.06 4.02-5.1 8.94-4.38l-.06 7.32c-5.64-.66-8.7 1.26-8.7 6V98H144V66.68h6.78Z"/>
                        <path fill="#54645E" d="M226.004 37.372c3.384 1.116 6.3 2.34 6.3 6.12 0 4.356-3.456 5.904-7.704 5.904-2.988 0-5.904-.864-7.92-2.196l1.656-3.78c2.016 1.116 4.14 1.728 6.552 1.728 1.944 0 2.52-.468 2.52-1.44 0-.864-.684-1.296-2.772-1.908-4.536-1.368-7.452-2.484-7.452-6.48 0-3.924 3.132-5.616 7.56-5.616 2.376 0 4.968.72 6.984 1.836l-1.584 3.924c-1.872-1.008-3.924-1.512-5.508-1.512-2.016 0-2.556.396-2.556 1.224 0 .792.576 1.116 3.924 2.196ZM210.864 44.644c.9 0 1.584-.396 2.808-1.44l-.72 5.04c-.828.648-2.304 1.152-3.852 1.152-3.06 0-5.04-2.376-5.04-5.688V34.78h-3.168V30.1h3.168v-4.86l4.968-2.484V30.1h4.536v4.68h-4.536v7.596c0 1.8.648 2.268 1.836 2.268ZM190.891 29.704c4.932 0 8.568 4.068 8.568 9.864 0 5.796-3.636 9.828-8.568 9.828-4.932 0-8.532-4.032-8.532-9.828 0-5.796 3.6-9.864 8.532-9.864Zm0 14.904c2.376 0 3.528-2.412 3.528-5.04 0-2.88-1.152-5.076-3.528-5.076-2.376 0-3.492 2.16-3.492 5.076 0 2.916 1.116 5.04 3.492 5.04ZM179.408 38.344V49h-4.968V38.776c0-3.384-1.152-4.392-3.204-4.392-2.052 0-3.24 1.008-3.24 4.284V49h-4.968V25.24l4.968-2.484v8.604c.864-.9 2.448-1.656 4.428-1.656 4.824 0 6.984 3.312 6.984 8.64ZM153.052 37.372c3.384 1.116 6.3 2.34 6.3 6.12 0 4.356-3.456 5.904-7.704 5.904-2.988 0-5.904-.864-7.92-2.196l1.656-3.78c2.016 1.116 4.14 1.728 6.552 1.728 1.944 0 2.52-.468 2.52-1.44 0-.864-.684-1.296-2.772-1.908-4.536-1.368-7.452-2.484-7.452-6.48 0-3.924 3.132-5.616 7.56-5.616 2.376 0 4.968.72 6.984 1.836l-1.584 3.924c-1.872-1.008-3.924-1.512-5.508-1.512-2.016 0-2.556.396-2.556 1.224 0 .792.576 1.116 3.924 2.196Z"/>
                        <path fill="#282E2F" fill-rule="evenodd" d="M120 8H8v112h112V8ZM0 0v128h128V0H0Z" clip-rule="evenodd"/>
                        <path fill="#AAFFDB" d="M104 88v16H80V88h24ZM76 88v16H52V88h24ZM48 88v16H24V88h24Z"/>
                        <path fill="#AAFFDB" fill-rule="evenodd" d="M95.208 22.617 15.257 25.41l1.535 43.974 79.951-2.792-1.535-43.974Zm-38.99 19.7a1 1 0 0 0-1.506.864l.02 10.79-.02 10.79a1 1 0 0 0 1.506.864L74.64 54.833a1 1 0 0 0 0-1.725L56.218 42.316Z" clip-rule="evenodd"/>
                        <path fill="#282E2F" fill-rule="evenodd" d="M104 32H24v44h80V32ZM56.218 42.316a1 1 0 0 0-1.506.865l.02 10.79-.02 10.79a1 1 0 0 0 1.506.864L74.64 54.833a1 1 0 0 0 0-1.725L56.218 42.316Z" clip-rule="evenodd"/>
                    </svg>
                </div>
                <div className="from-source-modal__first-run__image">
                    <img src="/img/from-source-modal-intro.jpg" alt="Add spots to shots Reels" />
                </div>
                <div className="from-source-modal__first-run__text">
                    <p>You can now <strong>share spots from Vault using shots Reels</strong> – in showreels, on your website, and a whole lot more coming soon.</p>
                    <p>Ready to get started?</p>
                    <button className="button button--large button--primary" onClick={clearFirstRun}>Add spots now</button>
                </div>
            </div>

        ) : (

            <>

                <div className="from-source-modal__logo">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 264 128">
                        <path fill="#282E2F" d="M250.684 98.6c-7.14 0-13.26-3.3-13.44-10.68l7.98-.24c.24 2.7 2.46 4.44 5.64 4.44 2.46 0 4.26-.96 4.26-2.7 0-5.16-16.74-3.18-16.74-14.04 0-5.82 5.22-9.36 11.76-9.36 7.32 0 12.24 4.26 12.36 9.9l-7.86.24c-.24-2.4-1.86-3.9-4.56-3.9-2.04 0-3.78.84-3.78 2.52 0 5.16 17.04 2.94 17.04 14.16 0 6.12-5.28 9.66-12.66 9.66ZM226.064 53h8.16v45h-8.16V53ZM207.89 66.02c8.94 0 15.24 6.12 14.82 18.84h-21.42c.36 4.14 3.3 6.96 7.14 6.96 3.24 0 5.46-1.74 6.6-4.38l7.14.78c-1.5 6.12-7.02 10.38-13.8 10.38-9.06 0-15.3-6.3-15.3-16.2 0-9.66 6-16.38 14.82-16.38Zm0 6.6c-3.72 0-6.42 2.58-6.6 6.66h13.2c-.06-3.96-2.76-6.66-6.6-6.66ZM176.336 66.02c8.94 0 15.24 6.12 14.82 18.84h-21.42c.36 4.14 3.3 6.96 7.14 6.96 3.24 0 5.46-1.74 6.6-4.38l7.14.78c-1.5 6.12-7.02 10.38-13.8 10.38-9.06 0-15.3-6.3-15.3-16.2 0-9.66 6-16.38 14.82-16.38Zm0 6.6c-3.72 0-6.42 2.58-6.6 6.66h13.2c-.06-3.96-2.76-6.66-6.6-6.66ZM150.78 66.68l1.2 4.32c1.44-3.06 4.02-5.1 8.94-4.38l-.06 7.32c-5.64-.66-8.7 1.26-8.7 6V98H144V66.68h6.78Z"/>
                        <path fill="#54645E" d="M226.004 37.372c3.384 1.116 6.3 2.34 6.3 6.12 0 4.356-3.456 5.904-7.704 5.904-2.988 0-5.904-.864-7.92-2.196l1.656-3.78c2.016 1.116 4.14 1.728 6.552 1.728 1.944 0 2.52-.468 2.52-1.44 0-.864-.684-1.296-2.772-1.908-4.536-1.368-7.452-2.484-7.452-6.48 0-3.924 3.132-5.616 7.56-5.616 2.376 0 4.968.72 6.984 1.836l-1.584 3.924c-1.872-1.008-3.924-1.512-5.508-1.512-2.016 0-2.556.396-2.556 1.224 0 .792.576 1.116 3.924 2.196ZM210.864 44.644c.9 0 1.584-.396 2.808-1.44l-.72 5.04c-.828.648-2.304 1.152-3.852 1.152-3.06 0-5.04-2.376-5.04-5.688V34.78h-3.168V30.1h3.168v-4.86l4.968-2.484V30.1h4.536v4.68h-4.536v7.596c0 1.8.648 2.268 1.836 2.268ZM190.891 29.704c4.932 0 8.568 4.068 8.568 9.864 0 5.796-3.636 9.828-8.568 9.828-4.932 0-8.532-4.032-8.532-9.828 0-5.796 3.6-9.864 8.532-9.864Zm0 14.904c2.376 0 3.528-2.412 3.528-5.04 0-2.88-1.152-5.076-3.528-5.076-2.376 0-3.492 2.16-3.492 5.076 0 2.916 1.116 5.04 3.492 5.04ZM179.408 38.344V49h-4.968V38.776c0-3.384-1.152-4.392-3.204-4.392-2.052 0-3.24 1.008-3.24 4.284V49h-4.968V25.24l4.968-2.484v8.604c.864-.9 2.448-1.656 4.428-1.656 4.824 0 6.984 3.312 6.984 8.64ZM153.052 37.372c3.384 1.116 6.3 2.34 6.3 6.12 0 4.356-3.456 5.904-7.704 5.904-2.988 0-5.904-.864-7.92-2.196l1.656-3.78c2.016 1.116 4.14 1.728 6.552 1.728 1.944 0 2.52-.468 2.52-1.44 0-.864-.684-1.296-2.772-1.908-4.536-1.368-7.452-2.484-7.452-6.48 0-3.924 3.132-5.616 7.56-5.616 2.376 0 4.968.72 6.984 1.836l-1.584 3.924c-1.872-1.008-3.924-1.512-5.508-1.512-2.016 0-2.556.396-2.556 1.224 0 .792.576 1.116 3.924 2.196Z"/>
                        <path fill="#282E2F" fill-rule="evenodd" d="M120 8H8v112h112V8ZM0 0v128h128V0H0Z" clip-rule="evenodd"/>
                        <path fill="#AAFFDB" d="M104 88v16H80V88h24ZM76 88v16H52V88h24ZM48 88v16H24V88h24Z"/>
                        <path fill="#AAFFDB" fill-rule="evenodd" d="M95.208 22.617 15.257 25.41l1.535 43.974 79.951-2.792-1.535-43.974Zm-38.99 19.7a1 1 0 0 0-1.506.864l.02 10.79-.02 10.79a1 1 0 0 0 1.506.864L74.64 54.833a1 1 0 0 0 0-1.725L56.218 42.316Z" clip-rule="evenodd"/>
                        <path fill="#282E2F" fill-rule="evenodd" d="M104 32H24v44h80V32ZM56.218 42.316a1 1 0 0 0-1.506.865l.02 10.79-.02 10.79a1 1 0 0 0 1.506.864L74.64 54.833a1 1 0 0 0 0-1.725L56.218 42.316Z" clip-rule="evenodd"/>
                    </svg>
                </div>

                <SelectedSpotsHeader isSuccess={isSuccess} csUser={csUser} spotsCount={spotsData.length}
                                     client={client} setClient={setClient} inQueue={inQueue} />

                <div className="from-source-modal__spots">
                    {isError ? <div className="center-loading">Unable to load spots.</div> : ''}
                    {isLoading ? <div className="center-loading"><PuffLoader /></div> : spotsData.map((clip) => (
                    <div className={cn('clip-card', { 'clip-card--large': spotsData.length === 1 }, {'clip-card--error': clip.fetchingError === true})} key={clip.id}>
                        <div className="clip-card__content">
                            <div className="clip-card__image">
                                {!clip.loaded ? <div className="clip-card__loader"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z" /></svg></div> : <img src={clip.getThumbnail()} alt={clip.title} />}
                            </div>
                            <div className="clip-card__details">
                                <h2>{clip.title}</h2>
                                <h3>{clip.client}</h3>
                            </div>
                            {spotsData.length > 1 && isSuccess === false &&
                            <div className="clip-card__remove" onClick={removeSpot} data-spot-id={clip.id}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
                            </div>
                            }
                        </div>
                        {clip.fetchingError === true ? (
                            <div className="clip-card__error"><span>This spot can't be added to your library</span></div>
                        ) : (
                            <>
                            {clip.isImported() === true && !isSuccess ? <div className="clip-card__exists">
                                <span>Spot is already in your library – credits will be updated</span>
                                <a href={`${buildClientInstanceUri(client)}/admin/media/edit/${clip.mediaId}`} target="_blank" rel="noreferrer noopener">View spot</a>
                            </div> : ''}
                            {isSuccess && !inQueue &&
                            <a href={`${buildClientInstanceUri(client)}/admin/media/edit/${clip.mediaId}`} target="_blank" rel="noreferrer noopener" className="clip-card__success">
                                <div className="clip-card__success__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>
                                </div>
                            </a>
                            }
                            </>
                        )}
                    </div>
                    ))}
                </div>

                <div className="from-source-modal__actions">
                    {!isError ? <SelectedSpotsImportButton
                        isLoading={detailsLoading} isImporting={isImporting} isSuccess={isSuccess} inQueue={inQueue}
                        importingProgress={importingProgress} importingDuration={importingDuration} importingMessage={importingMessage}
                        spotsCount={spotsData.length} importSpots={importSpots} client={client} />
                        : ''}
                </div>
            </>

        )}

        </div>

    );
}

export default SelectedSpotsApp;
